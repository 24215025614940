import {Image} from "primereact/image";
import {InfoPageLayout} from '../../../components/info-page-layout/info-page-layout.component';
import {PAGE_TEXTS} from '../../../constants/texts';

const AboutPage = () => {
  return (
    <InfoPageLayout
      title={'О компании'}
      titleImageUrl={'/images/about-title.jpg'}
    >
      <span>
        {PAGE_TEXTS.companyAbout.map((line, index) => <p key={index}>{line}</p>)}
      </span>
      <div
        className={'flex flex-column align-items-center'}
      >
        <Image
          src={'/images/145035.jpg'}
          width={'100%'}
        />
      </div>
    </InfoPageLayout>
  );
}

export default AboutPage;
