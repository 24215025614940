import {
  FileDocumentCategoryEnum,
  FileDocumentEntity,
  FileDocumentSubCategoryEnum,
  FileDocumentTypeEnum
} from '../../definitions/common.definitions';

export const stabsDisclosure: FileDocumentEntity[] = [
  {
    date: '10-11-2022',
    category: FileDocumentCategoryEnum.REPORTS,
    subCategory: FileDocumentSubCategoryEnum.SEPARATE_ACCOUNTING,
    name: 'Показатели раздельного учета таблица 1-3',
    url: 'https://sibseti.su/doc/%D0%9F%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D0%B5%D0%BB%D0%B8%20%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE%20%D1%83%D1%87%D0%B5%D1%82%D0%B0%20%D0%B7%D0%B0%202023%20%D0%B3%D0%BE%D0%B4%20%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%201-3.xls',
    type: FileDocumentTypeEnum.XLS,
  },
  {
    date: '10-11-2022',
    category: FileDocumentCategoryEnum.REPORTS,
    subCategory: FileDocumentSubCategoryEnum.SEPARATE_ACCOUNTING,
    name: 'Расшифровка расходов таблица 1-6',
    url: 'https://sibseti.su/doc/%D0%A0%D0%B0%D1%81%D1%88%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%BA%D0%B0%20%D1%80%D0%B0%D1%81%D1%85%D0%BE%D0%B4%D0%BE%D0%B2%20%D0%B7%D0%B0%202023%20%D0%B3%D0%BE%D0%B4%20%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%201-6.xls',
    type: FileDocumentTypeEnum.XLS,
  },
  {
    date: '10-11-2022',
    category: FileDocumentCategoryEnum.REPORTS,
    subCategory: FileDocumentSubCategoryEnum.SEPARATE_ACCOUNTING,
    name: 'Информация о структуре и объеме затрат на оказание услуг по передаче электрической энергии 2023',
    url: 'https://sibseti.su/doc/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F%20%D0%BE%20%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D1%83%D1%80%D0%B5%20%D0%B8%20%D0%BE%D0%B1%D1%8A%D0%B5%D0%BC%D0%B0%D1%85%20%D0%B7%D0%B0%D1%82%D1%80%D0%B0%D1%82%20%D0%BD%D0%B0%20%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%20%D0%BF%D0%BE%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%B0%D1%87%D0%B5%20%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B9%20%D1%8D%D0%BD%D0%B5%D1%80%D0%B3%D0%B8%D0%B8%202023.xlsx',
    type: FileDocumentTypeEnum.XLS,
  },
];
