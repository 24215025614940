import {MenuItem} from "primereact/menuitem";

export const NAVIGATION_ITEMS: MenuItem[] = [
  {
    label: 'Потребителям',
    items: [
      [
        {
          items: [
            { label: 'Плановые отключения' },
            // { label: 'Технологическое присоединение' },
            // { label: 'Передача электроэнергии' },
            { label: 'Карта присутствия' },
            { label: 'Личный кабинет' },
          ]
        },
      ],
    ],
  },
  {
    label: 'Документы',
    items: [
      [
        {
          items: [
            { label: 'Раскрытие информации', url: '/documents/disclosure' },
          ]
        }
      ]
    ]
  },
  {
    label: 'Калькулятор',
  },
  {
    label: 'Компания',
    items: [
      [
        {
          items: [
            { label: 'О комании', url: '/company/about' },
            { label: 'Контакты', url: '/company/contacts' },
          ],
        },
      ],
      // [
      //   {
      //     label: 'ЭСС',
      //     items: [
      //       { label: 'О нас', url: '/company/about' },
      //       { label: 'Контакты', url: '/company/contacts' },
      //       // { label: 'Документы' },
      //       // { label: 'Реквизиты' },
      //       // { label: 'Руководство' },
      //       // { label: 'Миссия и ценности' },
      //       // { label: 'Информационная детяельность' },
      //       // { label: 'Электролаборатория' },
      //     ],
      //   },
      // ],
      // [
      //   {
      //     label: 'Документы',
      //     items: [
      //       { label: 'Раскрытие информации' },
      //       // { label: 'Оценка условий труда' },
      //       // { label: 'Долгосрочные параметры регулирования' },
      //       // { label: 'Поданные заявки на техническое присоединение' },
      //       // { label: 'Заключённые договора на техническое присоединение' },
      //       // { label: 'Закупочная деятельность' },
      //     ],
      //   }
      // ],
      // [
      //   {
      //     label: 'Финансовые показатели',
      //     items: [
      //       { label: 'Раздельный учет' },
      //       { label: 'Финансовая информация' },
      //       { label: 'Учётная политика' },
      //     ]
      //   }
      // ]
    ],
  },
];
