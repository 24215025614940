import {ICarouselScreenEntity} from '../../components/carousel/carousel.interface';
import {CarouselComponent} from '../../components/carousel/carousel.component';

export const HomePage = () => {
  const items: ICarouselScreenEntity[] = [
    {
      title: 'Электрические сети Сибири',
      subTitle: 'Оказываем услуги по передачи электрической энергии и технологическому присоединению в Красноярском крае',
      buttonText: 'Узнать о нас больше',
      route: '/',
      image: 'images/carousel04.jpg',
    },
    {
      title: 'Технологическое присоединение',
      subTitle: 'Обеспечим возможность электроснабжения вновь построенных объектов или увеличим мощность потребления уже подключённых объектов',
      buttonText: 'Рассчитать стоимость присоединения',
      route: '/',
      image: 'images/carousel02.jpg',
    },
    {
      title: 'Передача электроэнергии',
      subTitle: 'Предоставляем энергию гарантирующим поставщикам, энергосбытовым организациям, потребителям электроэнергии, сетевым организациям в Красноярском крае',
      buttonText: 'Ознакомиться подробнее',
      route: '/',
      image: 'images/carousel06.jpg',
    },
  ];

  return (
    <CarouselComponent
      items={items}
    />
  );
}
