import {stabsDisclosure} from './stabs';
import {
  ApiResponseEntityT,
  FileDocumentEntity,
} from '../../definitions/common.definitions';

export const useDisclosure = (): ApiResponseEntityT<FileDocumentEntity[]> => {
  const data = stabsDisclosure;

  return {
    data,
  }
}
