import {FC} from "react";
import SGrid, {SGridProps} from '../grid/grid.component';

export type ManagerProps = {
  data?: any[];
  gridConfig: SGridProps;
}

const ManagerComponent:FC<ManagerProps> = (props) => {
  const {
    data,
    gridConfig,
  } = props;

  return (
    <>
      <SGrid
        {...gridConfig}
        value={data}
      />
    </>
  );
}

export default ManagerComponent;
