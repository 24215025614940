import {FileCategoryEntity} from '../../definitions/common.definitions';

export const stabsDocumentCategories: FileCategoryEntity[] = [
  {
    id: 0,
    name: 'Отчеты',
  },
  // Отчеты начало
  {
    id: '0-1',
    name: 'Раздельный учет',
    parentId: 0,
  },
  {
    id: '0-2',
    name: 'Структура и объем затрат',
    parentId: 0,
  },
  {
    id: '0-3',
    name: 'Результаты специальной оценки условий труда',
    parentId: 0,
  },
  {
    id: '0-4',
    name: 'Долгосрочные параметры регулирования',
    parentId: 0,
  },
  {
    id: '0-5',
    name: 'Финансовая информация',
    parentId: 0,
  },
  {
    id: '0-6',
    name: 'Учетная политика',
    parentId: 0,
  },
  // Отчеты конец
  {
    id: 1,
    name: 'Технологическое присоединение',
  },
  {
    id: 2,
    name: 'Передача электроэнергии',
  },
  {
    id: 3,
    name: 'Закупочная деятельность',
  },
  {
    id: 4,
    name: 'Инвестиционная деятельность',
  },
  {
    id: 5,
    name: 'О качестве обслуживания',
  },
  {
    id: 6,
    name: 'Ремонтная программа',
  },
  {
    id: 7,
    name: 'Электролаборатория',
  },
  {
    id: 8,
    name: 'Плановые отключения',
  },
  {
    id: 9,
    name: 'Нормативные документы',
  },
];
