import React from 'react';

export interface ICommonComponentProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export type EntityIdT = number | string;

export type FileDocumentEntity = {
  id?: EntityIdT;
  date: string;
  name: string;
  url: string;
  type: FileDocumentTypeEnum;
  category: FileDocumentCategoryEnum;
  subCategory: FileDocumentSubCategoryEnum;
}

export type FileCategoryEntity = {
  id: EntityIdT;
  name: string;
  parentId?: number;
}

export type ApiResponseEntityT<EntityT> = {
  data?: EntityT;
  progress?: boolean;
}

export enum FileDocumentTypeEnum {
  PDF = 'pdf',
  XLS = 'xls',
  DOC = 'doc',
  XML = 'xml',
  IMG = 'img',
}

export enum FileDocumentCategoryEnum {
  DISCLOSURE,
  PROCUREMENT,
  REPORTS,
}

export enum FileDocumentSubCategoryEnum {
  SEPARATE_ACCOUNTING,
}