import React, {FC, useMemo} from 'react';
import {DataTable, DataTableProps} from 'primereact/datatable';
import {Column, ColumnProps} from 'primereact/column';

export type SGridProps = DataTableProps<any> & {
  columns?: ColumnProps[];
};

const SGrid: FC<SGridProps> = (props) => {
  const {
    columns = [],
  } = props;

  const columnElements = useMemo(() => {
    return columns.map((column) => (
      <Column
        key={column.field}
        {...column}
      />
    ));
  }, [columns]);

  return (
    <DataTable
      stripedRows={true}
      tableStyle={{ minWidth: '100%' }}
      emptyMessage={'Данные отсутствуют'}
      {...props}
    >
      {columnElements}
    </DataTable>
  );
}

export default SGrid;