import {stabsDocumentCategories} from './stabs';
import {ApiResponseEntityT, FileCategoryEntity} from '../../definitions/common.definitions';

export const useDocumentCategories = (): ApiResponseEntityT<FileCategoryEntity[]> => {
  const data = stabsDocumentCategories;

  return {
    data,
  }
}
