import * as R from 'ramda';
import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';

import {Dropdown} from 'primereact/dropdown';
import {useDisclosure} from '../../../models/disclosure/useDisclosure';
import {useDocumentCategories} from '../../../models/document-categories/useDocumentCategories';
import {FileCategoryEntity, FileDocumentEntity} from '../../../definitions/common.definitions';
import {InfoPageLayout} from '../../../components/info-page-layout/info-page-layout.component';
import ManagerComponent from '../../../components/manager/manager.component';
import {ICON_MAP} from '../../../components/manager/manager.support';

const DisclosurePage: FC = () => {
  const {
    data: documents,
  } = useDisclosure();
  const {
    data: categories,
  } = useDocumentCategories();
  const [ category, setCategory ] = useState<FileCategoryEntity>();
  const [ subCategory, setSubCategory ] = useState<FileCategoryEntity>();

  const topLevelCategories = categories?.filter((cat) => R.isNil(cat.parentId));
  const subCategories = categories?.filter((cat) => cat.parentId === category?.id);

  return (
    <InfoPageLayout
      title={'Раскрытие информации'}
    >
      <div
        className={'my-2'}
      >
        <Dropdown
          value={category}
          onChange={(e) => setCategory(e.value)}
          optionLabel={'name'}
          options={topLevelCategories}
          placeholder={'Категория'}
          className={'w-full md:w-14rem'}
          emptyMessage={'Нет данных'}
        />
        {
          !R.isEmpty(subCategories) && (
            <Dropdown
              value={subCategory}
              options={subCategories}
              optionLabel={'name'}
              onChange={(e) => setSubCategory(e.value)}
              placeholder={'Подкатегория'}
              className={'w-full md:w-14rem ml-2'}
              emptyMessage={'Нет данных'}
            />
          )
        }
      </div>
      <ManagerComponent
        gridConfig={{
          showHeaders: false,
          columns: [
            {
              field: 'name',
              body: (itm: FileDocumentEntity) => <Link to={itm.url}>{itm.name}</Link>
            },
            {
              field: 'date',
            },
            {
              field: 'type',
              body: (itm: FileDocumentEntity) => <i className={`pi ${ICON_MAP[itm.type]}`}></i>,
            },
          ]
        }}
        data={documents}
      />
    </InfoPageLayout>
  );
}

export default DisclosurePage;
