import React from 'react';
import 'moment/locale/ru';
import moment from 'moment';
import {RouterProvider} from 'react-router-dom';

import router from './navigation/routes';
moment.locale('ru');

function App() {
  return <RouterProvider router={router} />;
}

export default App;
