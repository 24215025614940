import {PrimeReactProvider} from 'primereact/api';
import {createBrowserRouter, Outlet} from 'react-router-dom';
import {HomePage} from '../pages/home/home.page';
import {NavigationComponent} from '../components/navigation/navigation.component';
import React from 'react';
import DisclosurePage from '../pages/documents/disclosure/disclosure.page';
import AboutPage from '../pages/company/about/about.page';

const ProvidersLayout = () => (
  <PrimeReactProvider>
    <NavigationComponent/>
    <Outlet/>
  </PrimeReactProvider>
);

const router = createBrowserRouter([
  {
    element: <ProvidersLayout/>,
    children: [
      {
        path: '/',
        element: <HomePage/>,
      },
      {
        path: 'documents',
        children: [
          {
            path: 'disclosure',
            element: <DisclosurePage/>,
          },
        ],
      },
      {
        path: 'company',
        children: [
          {
            path: 'about',
            element: <AboutPage/>,
          },
        ],
      },
    ],
  },
]);

export default router;
